.winner_modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.winner_modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 100%;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.winner_modal-content h2 {
    font-size: 24px;
}

.winner_modal-content p {
    font-size: 18px;
    margin: 20px 0;
}

.winner_modal-content button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.winner_modal-content button:hover {
    background-color: #45a049;
}
