/* Navbar容器 */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2d2d2d;  /* 根据背景图片，选择深灰色 */
  padding: 15px 20px;  /* 调整padding，缩小navbar的高度 */
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);  /* 高级感的阴影效果 */
}

/* 左侧 StreamNZ 标题 */
.navbar-left h2 {
  margin: 0;
  font-size: 26px;  /* 调整标题字体大小 */
  font-weight: bold;
  color: #f0f0f0;  /* 亮色文字，更醒目 */
}

/* 右侧按钮容器 */
.navbar-right {
  display: flex;
  align-items: center;
  gap: 30px;  /* 增加按钮之间的间距 */
  padding-right: 30px;  /* 调整右侧padding */
}

/* 登录和注册按钮，以及通用的按钮样式 */
.navbar-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;  /* 调整padding，使按钮更紧凑 */
  background-color: #ff6347;  /* 使用亮眼的颜色让按钮突出 */
  color: white;
  border: none;
  border-radius: 6px;  /* 圆角设计，更现代 */
  font-size: 14px;  /* 调整字体大小 */
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;  /* 增加过渡效果 */
  width: 100px;  /* 缩小按钮宽度 */
}

/* 鼠标悬停效果 */
.navbar-btn:hover {
  background-color: #ff4500;
  transform: translateY(-2px);  /* 悬停时微微上移，增加交互感 */
}

/* 设置按钮 */
.settings-btn {
  background-color: #d9534f;  /* 绿色背景，表示设置功能 */
}

/* 登出按钮 */
.logout-btn {
  background-color: #d9534f;  /* 红色背景，表示登出 */
}

/* 用户信息显示 */
.user-info span {
  font-size: 14px;
  color: #f0f0f0;
  margin-right: 10px;
}
