body, html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background-color: #121212;
    color: white;
    height: 100%;
    background: url('https://ai.streamnz.com/images/background-pic2.png') no-repeat center center fixed;
    background-size: cover;
}


.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 50px;
    height: 100vh;
    text-align: center;
    position: relative;
}

h1 {
    font-size: 4rem;
    margin-bottom: 10px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

h2 {
    font-size: 2rem;
    margin-bottom: 30px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.start-btn {
    background-color: #ff4081;
    color: white;
    font-size: 1.5rem;
    padding: 15px 30px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: 0.3s;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    min-width: 150px; /* 设置按钮的最小宽度 */
    max-width: 400px; /* 设置按钮的最大宽度 */
    width: 50%; /* 可选：设置宽度为父容器的50% */
}

.start-btn:hover {
    background-color: #e91e63;
    transform: scale(1.05);
}

.login-modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
}

.login-modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    position: relative;
}

.login-modal-content input {
    margin: 10px 0;
    padding: 10px;
    width: 90%;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.login-modal-content button {
    background-color: #3f51b5;
    color: white;
    font-size: 1rem;
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 100%;
}

.login-modal-content button:hover {
    background-color: #303f9f;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    background-color: #ff4081;
    border-radius: 50%;
    color: white;
    font-size: 18px;
    text-align: center;
    line-height: 25px;
    cursor: pointer;
    transition: 0.3s;
}

.close-btn:hover {
    background-color: #e91e63;
}
.user-info {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 1.2em;
    color: #fff;  /* 或者你想要的其他颜色 */
}

