.gomoku-game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #282c34;
    color: white;
}

.gomoku-game-board {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 600px;
    background-color: #f5deb3;
    border: 2px solid #8b4513;
    background-image:
        linear-gradient(to right, black 1px, transparent 1px),
        linear-gradient(to bottom, black 1px, transparent 1px);
    background-size: 40px 40px;
}

.gomoku-row {
    display: flex;
    flex-direction: row;
}

.gomoku-cell {
    width: 40px;
    height: 40px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gomoku-piece {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.gomoku-piece-black {
    background-color: black;
    z-index: 10; /* 提高 z-index，确保黑子显示在棋盘上方 */
}

.gomoku-piece-white {
    background-color: white;
    z-index: 10; /* 提高 z-index，确保白子显示在棋盘上方 */
}

/* 为选择按钮设计的样式 */
.gomoku-player-select-white-black {
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    border: none;
    border-radius: 25px;
    color: white;
    font-size: 18px;
    padding: 15px 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 10px auto;
    width: 300px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.gomoku-player-select-white-black:hover {
    background: linear-gradient(135deg, #ff512f, #dd2476);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
}

.gomoku-player-select-white-black:active {
    transform: scale(0.98);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
